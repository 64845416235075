import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './router'
import Index from './Index'
import walletManager from "./walletManager";

import 'bootstrap'

Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(walletManager);

// Vue.component('main-component', );


    new Vue({
        render: h => h(Index),
        el: '#app',
        router,
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title + ' | IncaNFT';
                }
            },
        }
    });