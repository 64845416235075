<template>
    <div class="container crosspunk-details">
        <div class="row" style="min-height: calc(100vh - 225px);">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div v-if="punk_loading" class="text-center">
                    <button  type="button" class="btn" style="margin: 0 auto;">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;margin-bottom: 4px" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
                <div v-else>
                    <div class="row" >
                        <div class="col-md-6">
                            <img v-if="crypto_Punks.indexOf(currentPunk.idx) > -1" class="crosspunks-card-img-top pixelated" :src="(`/crypto/${currentPunk.idx}.png`)" id="punkAvatar">
                            <img v-else class="crosspunks-card-img-top pixelated" :src="(`https://blue-improved-swallow-68.mypinata.cloud/ipfs/bafkreihimv4ufkduj3mw3nz2ifpxb35baesme32cv7odloxu5g3qhmha24`)" id="punkAvatar">
                        </div>
                        <div class="col-md-6" >
                            <div class="pl-3">
                                <div class="row">
                                    <h3>IncaNFT #{{ currentPunk.idx }}</h3>
                                </div>
                                <!-- <div class="row">
                                    <h5>{{ currentPunk.type == 'Crypto' ? 'Crypto Friend' : currentPunk.type }}</h5>
                                </div> -->
                                <div class="row" >
                                    <p v-if="this.token_owner">owned by : <a class="crosspunk-a" target="_blank" :href="'https://matchscan.io/address/'+ this.token_owner">{{ this.token_owner.substr(0, 8)}}</a></p>
                                    <p v-else >not mined</p>
                                </div>
                                <!-- <div class="row" >
                                    <span v-for="(tag, index) in currentPunk.attributes" v-bind:key="index" style="margin-right: 4px; margin-bottom: 4px;" class="badge badge-primary">{{ tag }}</span>
                                </div> -->
                                <!-- <div class="row" >
                                    <p style="font-size: 18px">Rank {{ currentPunk.rank }}</p>
                                </div> -->
                                <div class="row justify-content-between">
                                    <button id="get-avatar" class="btn crosspunk-btn" style="margin-bottom: 20px !important; " @click="getAvatar(currentPunk.idx)">Get avatar</button>
                                    <button id="transfer-punk" class="btn crosspunk-btn" @click="showTransfer()" v-if="this.walletStatus && this.token_owner && this.token_owner.toLowerCase() == this.walletAddr.toLowerCase() && !is_for_sale">
                                        Transfer NFT
                                    </button>
                                </div>
                                <hr />
                                <div v-if="this.token_owner">
                                    <div class="row" >
                                        <h5>Market Summary</h5>
                                    </div>
                                    <!-- <div v-if="!is_for_sale" class="row text-danger">
                                        <p>it's not for sale</p>
                                    </div> -->
                                    <div v-if="is_for_sale && walletStatus">
                                        <!-- <div class="row text-success">
                                            <p>Offered by owner for <span style="color: #e7e2e2">{{ sale_by_owner }} BNB</span></p>
                                        </div> -->
                                        <div>
                                            <!-- <div v-if="punkBids.hasBid" class="row text-warning" >
                                                <p>There is a bid of <span style="color: #e7e2e2">{{ punkBids.value }} BNB</span> for this punk from <a :href="'https://matchscan.io/address/'+ this.punkBids.bidder">{{ this.punkBids.bidder.substr(0, 8)}}</a></p>
                                            </div> -->
                                            <!-- <div v-if="punkBids.bidder === walletAddr" class="row mb-2">
                                                <div class="col-md-12" >
                                                    <button class="btn btn-warning btn-block" @click="cancelBid">
                                                        Cancel Bid
                                                        <div v-if="cancel_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div> -->

                                            <div v-if="this.token_owner.toLowerCase() !== walletAddr.toLowerCase()" class="row">
                                                <div class="col-md-6">
                                                    <button id="buy" class="btn crosspunk-btn btn-block" @click="buy">
                                                        Buy {{ sale_by_owner }} BNB
                                                        <div v-if="buy_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <!-- <div class="col-md-6" >
                                                    <button class="btn crosspunk-btn btn-block" @click="showBidModal()">Bid</button>
                                                </div> -->
                                            </div>
                                            <div v-else>
                                                <!-- <div v-if="punkBids && punkBids.hasBid" class="row mb-2">
                                                    <div class="col-md-12">
                                                        <button @click="acceptBid" class="btn btn-info btn-block" >
                                                            Accept Bid
                                                            <div v-if="accept_bid_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div> -->
                                                <div  class="row">
                                                    <div class="col-md-12">
                                                        <button id="cancel-selling" @click="cancelSelling" class="btn crosspunk-btn btn-block">
                                                            Cancel Selling
                                                            <div v-if="cancel_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="this.walletStatus && !is_for_sale && this.token_owner.toLowerCase() == this.walletAddr.toLowerCase()" class="row">
                                        <div class="col-md-12" >
                                            <!-- <button id="offer-for-sale" @click="showOfferForSale()" class="btn crosspunk-btn btn-block"> -->
                                            <button id="offer-for-sale" class="btn crosspunk-btn btn-block">
                                                Offer for sale - Soon!
                                            </button>
                                        </div>
                                    </div>
                                    <div v-if="!this.walletStatus" class="row">
                                        <div class="text-center" style="margin: 0 auto;">
                                            <button id="connect-wallet" @click="walletManager.connectToMetamask()" type="button" class="btn crosspunk-btn">
                                                Connect Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <p v-if="tx_error" class="text-danger">{{ tx_error }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mt-3">
                        <div style="margin-top: 40px; width: 100%"><h4 class="text-center">Market History</h4>
                            <table class="table table-sm table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>From</th>
                                        <th>Amount</th>
                                        <th>Txn</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in marketHistory" v-bind:key="row.txID">
                                        <td>
                                            <span v-if="row.methodName == 'buyPunk'">Bought</span>
                                            <span v-if="row.methodName == 'acceptBidForPunk'">Accept Bid</span>
                                            <span v-if="row.methodName == 'offerPunkForSale'">Offered</span>
                                            <span v-if="row.methodName == 'punkNoLongerForSale'">Offer Withdrawn</span>
                                            <span v-if="row.methodName == 'enterBidForPunk'">Bid</span>
                                            <span v-if="row.methodName == 'withdrawBidForPunk'">Bid Withdrawn</span>
                                        </td>
                                        <td>
                                            <a target="_blank" :href="'https://matchscan.io/address/'+ row.ownerAddress">
                                                {{ row.ownerAddress.substr(0, 8) }}
                                            </a>
                                        </td>
                                        <td>
                                            <span v-if="row.methodName == 'offerPunkForSale'">
                                                {{ walletManager.ethers.utils.formatEther(row.methodParam[1].hex) }} BNB ~(${{fixInThreeDec(walletManager.ethers.utils.formatEther(row.methodParam[1].hex) * row.trx_price)}})
                                            </span>
                                            <span v-if="row.methodName == 'enterBidForPunk' || row.methodName == 'buyPunk'">
                                                {{ walletManager.ethers.utils.formatEther(row.amount) }} BNB ~(${{fixInThreeDec(walletManager.ethers.utils.formatEther(row.amount) * row.trx_price)}})
                                            </span>
                                        </td>
                                        <td>
                                            <a target="_blank" :href="'https://matchscan.io/tx/' + row.txID">
                                                {{ (new Date(row.blockTimestamp / 1)).getFullYear() + "/" + ((new Date(row.blockTimestamp / 1)).getMonth() + 1) + "/" + (new Date(row.blockTimestamp / 1)).getDate() }}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="birthday">
                                        <td><span>Born</span></td>
                                        <td>
                                            <a target="_blank" :href="'https://matchscan.io/address/'+ birthday.mint_by">
                                                {{ birthday.mint_by.substr(0, 8) }}
                                            </a>
                                        </td>
                                        <td>1000 BNB</td>
                                        <td>
                                            <span>
                                                {{ (new Date(birthday.mint_date / 1)).getFullYear() + "/" + ((new Date(birthday.mint_date / 1)).getMonth() + 1) + "/" + (new Date(birthday.mint_date / 1)).getDate() }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
        <div v-if="modalTransfer">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Transfer punk</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="modalTransfer = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form" >
                                        <div class="form-group">
                                            <input v-model="transfer_wallet_address" type="text" class="form-control" placeholder="Enter Wallet Address">
                                        </div>
                                    </div>
                                    <div>
                                        <p v-if="transfer_error" class="text-danger">{{ transfer_error }}</p>
                                        <p v-if="transfer_msg" class="text-success">{{ transfer_msg }}</p>
                                    </div>
                                </div>
                                <div class="modal-footer" style="display: block">
                                    <button type="button" class="btn btn-secondary" style="float: left" @click="modalTransfer = false">Close</button>
                                    <div>
                                        <button id="submit" type="button" class="btn crosspunk-btn" @click="transfer" style="float: right">
                                            Submit
                                            <div v-if="transfer_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="modalOfferForSale">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Offer for sale</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="modalOfferForSale = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form" >
                                        <div class="form-group">
                                            <label>Price (BNB)</label>
                                            <input v-model="offer_price" type="number" min="0" class="form-control" placeholder="Enter price">
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>sale to special address (optional)</label>
                                            <small class="form-text text-muted">enter wallet address, if you want to sale to special address</small>
                                            <input v-model="offer_wallet_address" type="text" class="form-control" placeholder="Enter Wallet Address">
                                        </div> -->
                                    </div>
                                    <div>
                                        <p v-if="offer_error" class="text-danger">{{ offer_error }}</p>
                                        <p v-if="offer_msg" class="text-success">{{ offer_msg }}</p>
                                    </div>
                                </div>
                                <div class="modal-footer" style="display: block">
                                    <button type="button" class="btn btn-secondary " style="float: left" @click="modalOfferForSale = false">Close</button>
                                    <div v-if="is_approved_first_time === false">
                                        <button id="submit" type="button" class="btn crosspunk-btn" @click="offerForSale" style="float: right" :disabled="offer_btn_submit2_disable">
                                            2-Submit
                                            <div v-if="offer_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                        <button id="approve" type="button" class="btn crosspunk-btn" @click="approve()" style="float: right; margin-right: 4px;" :disabled="offer_btn_approve_disable">
                                            1-Approve
                                            <div v-if="offer_btn_approve_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div v-else >
                                        <button id="submit" type="button" class="btn crosspunk-btn" @click="offerForSale" style="float: right">
                                            Submit
                                            <div v-if="offer_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="modal_bid">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Bid</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="modal_bid = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form" >
                                        <div class="form-group">
                                            <label>price (BNB)</label>
                                            <input v-model="bid_price" type="number" :min="(punkBids.hasBid ? punkBids.value : '')" class="form-control" placeholder="Enter price">
                                        </div>
                                    </div>
                                    <div>
                                        <p v-if="bid_error" class="text-danger">{{ bid_error }}</p>
                                        <p v-if="bid_msg" class="text-success">{{ bid_msg }}</p>
                                    </div>
                                </div>
                                <div class="modal-footer" style="display: block">
                                    <button type="button" class="btn btn-secondary " style="float: left" @click="modal_bid = false">Close</button>
                                    <div>
                                        <button id="submit" type="button" class="btn crosspunk-btn" @click="bid" style="float: right">
                                            Submit
                                            <div v-if="bid_btn_loading" class="spinner-border" style="width: 1rem; height: 1rem;margin-bottom: 4px" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import domtoimage from 'dom-to-image';
import ConnectWallet from "../components/ConnectWallet.vue"

export default {
    name: "Details",
    data(){
        return {
            walletStatus : false,
            currentPunk : '',
            is_load_this_punk : false,
            punk_loading : true,
            token_owner : false,
            crypto_Punks : [3442, 910, 846, 794, 377, 373, 300, 224, 220, 81, 57],
            punks_attributes : {},
            is_for_sale : false,
            sale_by_owner : false,
            punkBids : false,
            modalOfferForSale : false,
            offer_btn_loading : false,
            offer_price : '',
            offer_wallet_address : '',
            offer_error : '',
            offer_msg : '',
            modalTransfer: false,
            transfer_btn_loading : false,
            transfer_wallet_address : '',
            transfer_error : '',
            transfer_msg : '',
            is_approved : false,
            is_approved_first_time : null,
            offer_btn_approve_disable : true,
            offer_btn_submit2_disable : true,
            offer_btn_approve_loading : false,
            cancel_btn_loading : false,
            modal_bid : false,
            bid_price : '',
            bid_error : '',
            bid_msg : '',
            bid_btn_loading : false,
            balance : 0,
            accept_bid_btn_loading : false,
            cancel_bid_btn_loading : false,
            buy_btn_loading : false,
            marketHistory : [],
            birthday : false,
            walletAddr: '',
            tx_error: '',
        }
    },
    components: {
        ConnectWallet
    },
    mounted() {
        if (this.$route.params.id >= 0 && this.$route.params.id < 100000) {
            // this.currentPunk = window.punks[this.$route.params.id];
            this.currentPunk = {
                                    "idx": this.$route.params.id,
                                    "type": "-",
                                    "attributes": ["Common"],
                                    "rank": '-'
                                };
            this.punks_attributes = window.punks_attributes;
            this.walletStatus = this.walletManager.walletStatus;

            setInterval(()=>{
                this.walletStatus = this.walletManager.walletStatus
                if(!this.is_load_this_punk){
                    this.is_load_this_punk = true;
                    this.loadData();
                }
            }, 100);
        } else {
            window.location.href = '/'
        }
    },
    methods: {
        getAvatar(id) {
            const node = document.getElementById('punkAvatar');
            domtoimage.toPng(node).then(function (dataUrl) {
                    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.href = dataUrl;
                    link.download = "crosspunk_" + id + ".png";
                    link.click();
	                document.body.removeChild(link);
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
        },
        async loadData() {
            try {
                await this.walletManager.checkId();
                let signer = await this.walletManager.web3Global.getSigner();
                this.walletAddr = await signer.getAddress();
            } catch (e) {
                console.log(e);
                this.is_load_this_punk = false;
            }

            if (!this.walletManager.nft.ownerOf) {
                this.is_load_this_punk = false;
            } else {
                try {
                    let loadFromServer = false;
                    // try {
                    //     let dataServer = await this.$http.get(`https://api.crosspunks.com/punks/${this.currentPunk.idx}`);
                    //     let punk = dataServer.data;

                    //     let token_owner = punk.owner;

                    //     if (token_owner == this.walletManager.dexAddr) {
                    //         if (punk.offer) {
                    //             this.token_owner = punk.offer.seller;
                    //             this.is_for_sale = true;
                    //             this.sale_by_owner = this.walletManager.ethers.utils.formatEther(punk.offer.min_value);
                    //         }
                    //     } else {
                    //         this.token_owner = token_owner;
                    //         this.is_for_sale = false;
                    //         this.sale_by_owner = false;
                    //         this.punkBids = false;
                    //         await this.checkApproved();
                    //     }

                    //     loadFromServer = true;
                    // } catch (e) {
                    //     console.log("can not read from server");
                    // }

                    let token_owner = await this.walletManager.nft.ownerOf(this.currentPunk.idx);

                    if (token_owner == this.walletManager.dexAddr) {
                        this.punkBids = await this.walletManager.dex.nftBids(this.currentPunk.idx);
                        if (this.punkBids && this.punkBids.hasBid) {
                            this.punkBids.value = this.punkBids.value / 1000000;
                        }

                        let offeredForSale = await this.walletManager.dex.nftOfferedForSale(this.currentPunk.idx);
                        this.token_owner = offeredForSale.seller;
                        this.is_for_sale = offeredForSale.isForSale
                        this.sale_by_owner = this.walletManager.ethers.utils.formatEther(offeredForSale.minValue);
                    } else {
                        this.token_owner = token_owner;
                        this.is_for_sale = false;
                        this.sale_by_owner = false;
                        this.punkBids = false;
                        await this.checkApproved();
                    }
            

                    this.is_approved_first_time = this.is_approved;

                    // if (this.walletStatus) {
                    //     this.balance = await signer.getBalance();
                    // } else {
                    //     this.balance = 0;
                    // }
                } catch (e) {
                    console.log(e);
                }
                this.punk_loading = false;
            }
        },
        async checkApproved() {
            if (this.token_owner.toLowerCase() === this.walletAddr.toLowerCase()) {
                this.is_approved = await this.walletManager.nft.isApprovedForAll(this.token_owner, this.walletManager.dexAddr);
            }
            if (this.is_approved) {
                this.offer_btn_approve_disable = true;
                this.offer_btn_submit2_disable = false;
            } else {
                this.offer_btn_approve_disable = false;
                this.offer_btn_submit2_disable = true;
            }
        },
        async showOfferForSale() {
            this.offer_error = "";
            this.offer_msg = "";
            this.offer_price = "";
            this.offer_wallet_address = "";

            if (this.is_approved) {
                this.offer_btn_approve_disable = true;
                this.offer_btn_submit2_disable = false;
            } else {
                this.offer_btn_approve_disable = false;
                this.offer_btn_submit2_disable = true;
            }

            this.modalOfferForSale = true;
        },
        async offerForSale() {
            let signer = await this.walletManager.web3Global.getSigner();
            let dexSigner = this.walletManager.dex.connect(signer);
            if (!this.offer_btn_loading) {
                this.offer_error = "";
                this.offer_msg = "";

                let price = parseFloat(this.offer_price);
                let address = this.offer_wallet_address.trim();

                if (!(price >= 0)) {
                    this.offer_error = "enter correct price (BNB)";
                } else if(0.2 > price) {
                    this.offer_error = "you can not enter price lower than 0.2 BNB";
                } else if((address.length > 0 && !this.walletManager.web3Global.utils.isAddress(address))) {
                    this.offer_error = "wallet address is not correct";
                } else if((address.length > 0 && address === this.walletAddr)) {
                    this.offer_error = "you can not enter your wallet address";
                } else {
                    this.offer_btn_loading = true;
                    try {
                        price = this.walletManager.ethers.utils.parseUnits(price.toString(), 'ether');

                        if (address.length > 0) {
                            await dexSigner.offerPunkForSaleToAddress(this.currentPunk.idx, price, address);
                        } else {
                            await dexSigner.offerPunkForSale(this.currentPunk.idx, price);
                        }

                        setTimeout(() => {
                            this.offer_msg = "Your transaction has been broadcast to network!";
                            setTimeout(async()=>{
                                await this.loadData();
                                this.offer_btn_loading = false;
                                this.modalOfferForSale = false;
                            }, 1500);
                        }, 10000);
                    } catch(e) {
                        await this.loadData();
                        console.log(e);
                        this.offer_error = "failed!";
                        this.offer_btn_loading = false;
                    }
                }
            }
        },
        async approve() {
            let signer = await this.walletManager.web3Global.getSigner();
            let nftSigner = this.walletManager.nft.connect(signer);
            if (!this.offer_btn_approve_loading) {
                this.offer_btn_approve_loading = true;
                try {
                    await nftSigner.setApprovalForAll(this.walletManager.dexAddr, true);
                } catch (e) {
                    this.offer_btn_approve_loading = false;
                }

                setTimeout(async () => {
                    await this.checkApproved();
                    this.offer_btn_approve_loading = false;
                }, 10000);
            }
        },
        async cancelSelling() {
            let signer = await this.walletManager.web3Global.getSigner();
            let dexSigner = this.walletManager.dex.connect(signer);

            this.tx_error = '';

            if (!this.cancel_btn_loading) {
                this.cancel_btn_loading = true;
                try {
                    await dexSigner.punkNoLongerForSale(this.currentPunk.idx);

                    setTimeout(async () => {
                        await this.loadData();
                        this.cancel_btn_loading = false;
                    }, 10000)
                } catch (e) {
                    await this.loadData();

                    if (e.data) {
                        this.tx_error = e.data.message;
                    } else {
                        this.tx_error = e.message;
                    }

                    this.cancel_btn_loading = false;
                }
            }
        },
        async showBidModal() {
            this.modal_bid = true
            this.bid_error = "";
            this.bid_msg = "";
            this.bid_price = "";
        },
        // async bid() {
        //     let from = await this.walletManager.web3Global.eth.getCoinbase();
        //     if (!this.bid_btn_loading) {
        //         try {
        //             this.balance = await this.walletManager.web3Global.eth.getBalance(this.walletAddr);
        //         } catch (e) {
        //             console.log(e);
        //         }
        //         this.bid_error = "";
        //         this.bid_msg = "";
        //         let price = parseInt(this.bid_price);
        //         if (!(price >= 0)) {
        //             this.bid_error = "enter correct price (BNB)";
        //         } else if(this.punkBids && this.punkBids.hasBid && this.punkBids.value && this.punkBids.value >= price) {
        //             this.bid_error = "you can not bid lower than last bid";
        //         } else if(10000 > price) {
        //             this.bid_error = "you can not bid lower than 10K BNB";
        //         } else if(this.balance < (price * 1000000)) {
        //             this.bid_error = "you don't have enough BNB";
        //         } else {
        //             this.bid_btn_loading = true;
        //             try {
        //                 await this.walletManager.dex.methods.enterBidForPunk(this.currentPunk.idx).send({
        //                     from,
        //                     feeLimit: 100000000,
        //                     value: this.walletManager.web3Global.utils.toWei(price, 'ether'),
        //                     shouldPollResponse: false
        //                 });

        //                 setTimeout(() => {
        //                     this.bid_msg = "Your transaction has been broadcast to network!";
        //                     setTimeout(async () => {
        //                         await this.loadData();
        //                         this.bid_btn_loading = false;
        //                         this.modal_bid = false;
        //                     }, 1500);
        //                 }, 1000);
        //             } catch(e) {
        //                 await this.loadData();
        //                 this.bid_error = "failed!";
        //                 this.bid_btn_loading = false;
        //             }
        //         }
        //     }
        // },
        // async acceptBid() {
        //     let from = await this.walletManager.web3Global.eth.getCoinbase();
        //     if (!this.accept_bid_btn_loading) {
        //         this.accept_bid_btn_loading = true;
        //         try {
        //             await this.walletManager.dex.methods.acceptBidForPunk(this.currentPunk.idx, (this.punkBids.value * 1000000)).send({
        //                 from,
        //                 feeLimit: 100000000,
        //                 callValue: 0,
        //                 shouldPollResponse: false
        //             });

        //             setTimeout(async () => {
        //                 await this.loadData();
        //                 this.accept_bid_btn_loading = false;
        //             }, 2500);

        //         } catch (e) {
        //             this.accept_bid_btn_loading = false;
        //         }
        //     }
        // },
        // async cancelBid() {
        //     let from = await this.walletManager.web3Global.eth.getCoinbase();
        //     if (!this.cancel_btn_loading) {
        //         this.cancel_btn_loading = true;
        //         try {
        //             await this.walletManager.dex.methods.withdrawBidForPunk(this.currentPunk.idx).send({
        //                 from,
        //                 feeLimit: 100000000,
        //                 callValue: 0,
        //                 shouldPollResponse: false
        //             });

        //             setTimeout(async () => {
        //                 await this.loadData();
        //                 this.cancel_btn_loading = false;
        //             }, 2500);
        //         } catch(e) {
        //             await this.loadData();
        //             this.cancel_btn_loading = false;
        //         }
        //     }
        // },
        async buy() {
            let signer = await this.walletManager.web3Global.getSigner();
            let dexSigner = this.walletManager.dex.connect(signer);

            this.tx_error = '';

            if (!this.buy_btn_loading) {
                this.buy_btn_loading = true;
                try {
                    await dexSigner.buyPunk(
                        this.currentPunk.idx,
                        { value: this.walletManager.ethers.utils.parseUnits(this.sale_by_owner, 'ether') }
                    );

                    setTimeout(async () => {
                        await this.loadData();
                        this.buy_btn_loading = false;
                    }, 10000);
                } catch(e) {
                    await this.loadData();

                    if (e.data) {
                        this.tx_error = e.data.message;
                    } else {
                        this.tx_error = e.message;
                    }

                    this.buy_btn_loading = false;
                }
            }
        },
        async showTransfer() {
            this.transfer_error = "";
            this.transfer_msg = "";
            this.transfer_wallet_address = "";

            this.modalTransfer = true;
        },
        async transfer() {
            let signer = await this.walletManager.web3Global.getSigner();
            let nftSigner = this.walletManager.nft.connect(signer);
            if (!this.transfer_btn_loading) {
                this.transfer_error = "";
                this.transfer_msg = "";

                let address = this.transfer_wallet_address.trim();

                if (address.length > 0 && !this.walletManager.ethers.utils.isAddress(address)) {
                    this.transfer_error = "Wallet address is not correct";
                } else if((address.length > 0 && address.toLowerCase() === this.walletAddr.toLowerCase())) {
                    this.transfer_error = "You can not enter your wallet address";
                } else {
                    this.transfer_btn_loading = true;
                    try {
                        await nftSigner.transferFrom(
                            this.walletAddr,
                            address,
                            this.currentPunk.idx,
                        );

                        setTimeout(async () => {
                            this.transfer_msg = "Your transaction has been broadcast to network!";
                            setTimeout(async()=>{
                                await this.loadData();
                                this.transfer_btn_loading = false;
                                this.modalTransfer = false;
                            }, 1500);
                        }, 10000);
                    } catch(e) {
                        await this.loadData();
                        console.log(e);
                        this.transfer_error = "failed!";
                        this.transfer_btn_loading = false;
                    }
                }
            }
        },
        fixInThreeDec(str) {
            let _s = parseFloat(str);
            _s = +_s.toFixed(3);
            return _s;
        }
    }
};
</script>
